<template>
<div>
  <el-table
    :data="data"
    @row-click="taskDetail">
    <el-table-column
      prop="id"
      type="index"
      :index="indexMethod"
      label="编号">
    </el-table-column>
    <el-table-column
      prop="title"
      label="任务名称">
    </el-table-column>
    <el-table-column
      prop="amount"
      label="任务奖励">
    </el-table-column>
    <el-table-column
      label="任务级别">
      <template slot-scope="scope">
        <el-tag
            :type="{
              [TASK_LEVEL.COMMON]: 'success',
              [TASK_LEVEL.GENERAL]: 'info',
              [TASK_LEVEL.COMPLEX]: 'warning',
              [TASK_LEVEL.DIFFICULTY]: 'danger'
            }[scope.row.level]">
            {{
              {
                [TASK_LEVEL.COMMON]: '普通',
                [TASK_LEVEL.GENERAL]: '一般',
                [TASK_LEVEL.COMPLEX]: '复杂',
                [TASK_LEVEL.DIFFICULTY]: '困难'
              }[scope.row.level]
            }}
          </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      prop="deadline"
      label="任务截止时间(任务提交时间)">
    </el-table-column>
    <el-table-column
      label="状态">
      <template slot-scope="scope">
        <el-tag
        :type="{
            [TASK_STATUS.REJECT]: 'warning',
            [TASK_STATUS.SUCCESSFUL]: 'success',
            [TASK_STATUS.CANCELED]: 'info',
          }[scope.row.status]">
          {{
            {
              [TASK_STATUS.REJECT]: '已驳回',
              [TASK_STATUS.SUCCESSFUL]: '已完成',
              [TASK_STATUS.CANCELED]: '已取消',
            }[scope.row.status]
          }}
          </el-tag>
      </template>
    </el-table-column>
  </el-table>
  <div class="task_pages">
    <pagination
      @paginationChange="paginationChange"
      :pageInfo="pageInfo">
    </pagination>
  </div>
</div>
</template>

<script>
import api from '@/api'
import globalVar from '@/configs/globalVar'
import Pagination from '@/components/pagination/index.vue'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      data: []
    }
  },
  computed: {
    TASK_STATUS () {
      return globalVar.TASK_STATUS
    },
    TASK_LEVEL () {
      return globalVar.TASK_LEVEL
    }
  },
  created () {
    this.getHistoryList()
  },
  methods: {
    paginationChange (val) {
      this.pageInfo.pageNum = val
      this.getHistoryList()
    },
    getHistoryList () {
      api.HistoryTask({
        ...this.pageInfo,
        status: '2,4,5'
      }).then(res => {
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.pageInfo.total = total
          this.data = list
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('获取列表失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求错误')
      })
    },
    taskDetail (row) {
      this.$router.push(`/company/history-task-detail/${row.id}`)
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    }
  }

}
</script>

<style lang="scss" scoped>
.task_pages {
  margin-top: 20px;
  text-align: center;
}
</style>

<template>
  <div class="task_table">
    <div class="task_logo">
      <img src="@/assets/images/company-history-logo.png" alt="" class="task_history_message-img">
      <span class="task_history_message">任务管理</span>
    </div>
    <div>
      <el-button
        type="primary"
        size="small"
        class="company_release"
        @click="btnClick">
        发布任务
      </el-button>
    </div>
    <div class="table-list">
      <el-tabs
        v-model="activeName">
        <el-tab-pane
          label="当前任务"
          name="first">
          <current-task></current-task>
        </el-tab-pane>
        <el-tab-pane
          name="second"
          label="历史任务">
          <history-task></history-task>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import CurrentTask from './components/current-task-table.vue'
import HistoryTask from './components/history-task-table.vue'
export default {
  components: {
    CurrentTask,
    HistoryTask
  },
  data () {
    return {
      activeName: 'first'
    }
  },
  computed: {
    companyStatus () {
      return JSON.parse(localStorage.getItem('userInfo')).status
    }
  },
  methods: {
    btnClick () {
      if (this.companyStatus === 3 || this.companyStatus === 2) {
        this.$router.push('/company/task-release')
      } else {
        this.open()
      }
    },
    open () {
      this.$confirm('发布任务之前需要进行认证!充值完成后才能发布任务!', '提示', {
        confirmButtonText: '去认证',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push('/company/company-detail')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消认证'
        })
      })
    },
    handleToDetail (row, column, event) {
      this.$router.push(`/company/task-mgt-detail/${row.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.task_table {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 18px 30px 0px 30px;
  overflow: auto;
  .task_logo {
    display: flex;
    justify-content: flex-start;
    .task_history_message-img {
      padding-right: 10px;
      padding-bottom: 30px;
    }
    .task_history_message {
      padding-top: 1px;
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #303133;
    }
  }
  .table-list {
    padding-top: 23px;
  }
}
</style>

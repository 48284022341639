<template>
  <div>
    <el-table
      :data="data"
      @row-click="handleToDetail">
      <el-table-column
        prop="id"
        type="index"
        :index="indexMethod"
        label="编号">
      </el-table-column>
      <el-table-column
        prop="title"
        show-overflow-tooltip
        label="任务名称">
      </el-table-column>
      <el-table-column
        prop="amount"
        label="任务奖励">
      </el-table-column>
      <el-table-column
        prop="level"
        label="任务级别">
        <template slot-scope="scope">
          <el-tag
            :type="{
              [TASK_LEVEL.COMMON]: 'success',
              [TASK_LEVEL.GENERAL]: 'info',
              [TASK_LEVEL.COMPLEX]: 'warning',
              [TASK_LEVEL.DIFFICULTY]: 'danger'
            }[scope.row.level]">
            {{
              {
                [TASK_LEVEL.COMMON]: '普通',
                [TASK_LEVEL.GENERAL]: '一般',
                [TASK_LEVEL.COMPLEX]: '复杂',
                [TASK_LEVEL.DIFFICULTY]: '困难'
              }[scope.row.level]
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="deadline"
        label="任务截止时间(任务提交时间)">
      </el-table-column>
      <el-table-column
        label="状态">
        <template slot-scope="scope">
          <el-tag
            :type="{
              [TASK_STATUS.AUDIT]: 'info',
              [TASK_STATUS.PUBLISHED]: 'primary',
              [TASK_STATUS.ONGOING]: 'warning',
              [TASK_STATUS.SUCCESSFUL]: 'success',
              [TASK_STATUS.WAITING_FOR_REWARDS]: 'success',
              [TASK_STATUS.WAITING_FOR_COMPANY]: 'success',
            }[scope.row.status]">
          {{
            {
              [TASK_STATUS.AUDIT]: '待审核',
              [TASK_STATUS.PUBLISHED]: '已发布',
              [TASK_STATUS.ONGOING]: '进行中',
              [TASK_STATUS.SUCCESSFUL]: '已完成',
              [TASK_STATUS.WAITING_FOR_REWARDS]: '等待奖励发放',
              [TASK_STATUS.WAITING_FOR_COMPANY]: '用户已完成等待确认',
            }[scope.row.status]
          }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="task-pages">
      <pagination
          @paginationChange="paginationChange"
          :pageInfo="pageInfo">
      </pagination>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import globalVar from '@/configs/globalVar'
import Pagination from '@/components/pagination/index.vue'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      data: []
    }
  },
  computed: {
    TASK_STATUS () {
      return globalVar.TASK_STATUS
    },
    TASK_LEVEL () {
      return globalVar.TASK_LEVEL
    }
  },
  created () {
    this.getCurrentList()
  },
  methods: {
    paginationChange (val) {
      this.pageInfo.pageNum = val
      this.getCurrentList()
    },
    handleToDetail (row) {
      this.$router.push(`/company/task-mgt-detail/${row.id}`)
    },
    getCurrentList () {
      api.HistoryTask({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        status: '0,1,3,6,7'
      }).then(res => {
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.pageInfo.total = total
          this.data = list
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('获取列表失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求错误')
      })
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.task-pages {
  margin-top: 20px;
  text-align: center;
}
</style>
